import React from 'react';
import GatsbyImage from 'gatsby-image';
import ProductContainer from '../components/ProductContainer';
import Icon from '../components/Icon';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { getCartItemInfo } from '../utils';
import { RECIPES_ROUTE, BASE_URL, EMAIL_ADDRESS } from '../constants';

const BlogContent = ({ featuredProducts, marketOpen, additionalItems, blog, addToCart, cartItems }) => {
  const shareURL = `${BASE_URL}${RECIPES_ROUTE}${blog.path}`;
  const cartItemInfo = getCartItemInfo(cartItems);
  function renderProducts() {
    if (featuredProducts.length > 0) {
      return (
        <div>
          <h5 style={{marginTop: 48}}>Featured products</h5>
          <div className='grid-container add-width-variable'>
            {featuredProducts.map(({ node }, index) => {
              return (
                <div className='grid-item-farm-product blog-product' key={`product-${index}`}>
                  <ProductContainer
                      product={node}
                      addToCart={addToCart}
                      cartItem={cartItemInfo[node.id]}
                      marketOpen={marketOpen}
                  />
                </div>
              )
            })}
          </div>
        </div>
      )
    }
    return null;
  }
  return (
    <div className='layout-container'>
      <div className='layout-page-section'>
        <h2>{blog.title}</h2>
        {/* <p className='text-muted'>{blog.blogHeaderDate}</p> */}
        <div className='blog-btns'>
          <a className='btn share-fb' href={`https://www.facebook.com/sharer/sharer.php?u=${shareURL}`} target="_blank" rel="noopener noreferrer" title="Share on Facebook">
            <Icon iconName="facebook" width={20} />
          </a>
          <a className='btn share-twitter' href={`https://twitter.com/home?status=${shareURL}`} target="_blank" rel="noopener noreferrer" title="Share on Twitter">
            <Icon iconName="twitter" width={20} />
          </a>
          <a className='btn share-pinterest' href={`https://pinterest.com/pin/create/button/?url=${shareURL}&media=&description=`} target="_blank" rel="noopener noreferrer" title="Share on Pinterest">
            <Icon iconName="pinterest" width={20} />
          </a>
          <a className='btn white-btn' href={`mailto:${EMAIL_ADDRESS}?&subject=${encodeURI("Check out what I found on till!")}&body=${shareURL}`} title="Share via email">
            <Icon iconName="email" width={20} />
          </a>
        </div>
        <p>{blog.description}</p>
        <div className="blog-info-table">
          <table>
            <tbody>
              <tr>
                <td className="text-secondary"><Icon iconName="chefHat" width={20} /></td>
                <td>Prep:</td>
                <td>{blog.prepTime} minutes</td>
              </tr>
              <tr>
                <td className="text-teal"><Icon iconName="oven" width={22} /></td>
                <td>Cook:</td>
                <td>{blog.cookTime} minutes</td>
              </tr>
              <tr>
                <td className="text-primary"><Icon iconName="group" width={24} /></td>
                <td>Yield:</td>
                <td>Serves {blog.yieldValue}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='image-container'>
            <GatsbyImage fluid={blog.blogImage.childImageSharp.fluid} />
        </div>
        <div dangerouslySetInnerHTML={{__html: blog.content}} />
        {additionalItems.map(({ node }, index) => {
          if (node.type === "text") {
            return <div key={`additional-item-${index}`} dangerouslySetInnerHTML={{__html: node.value}} />
          }
          else if (node.type === "video") {
            return <div key={`additional-item-${index}`} className="video-container"><iframe width="100%" src={node.value} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" title="YouTube video" /></div>
          }
          else if (node.type === "ingredients" || node.type === "directions") {
            return (
              <div key={`additional-item-${index}`}>
                <h5>{node.type.slice(0,1).toUpperCase() + node.type.slice(1).toLowerCase()}:</h5>
                <div>
                  {blog[node.type].map((value, idx) => {
                      return (
                        <div className="blog-list-item" key={`li-${idx}`}>
                          <div className="item-adornment">{node.type === "directions" ? `${idx + 1}.` : <Icon iconName="seedling" width={16} />}</div>
                          <div>{value}</div>
                        </div>
                      )
                  })}
                </div>
              </div>
            )
          }
          else if (node.type === "image") {
            return (
              <div className='image-container' key={`additional-item-${index}`}>
                <GatsbyImage fluid={node.additionalImage.childImageSharp.fluid} />
              </div>
            )
          }
          return null;
        })}
        {renderProducts()}
      </div>
    </div>
  )
};
export default ({ data: { marketStatus: { marketOpen }, blog, allBlogAdditionalItem: { edges: additionalItems }, allProduct: { edges: featuredProducts } } }) => {
  return (
    <Layout pageTitle={blog.title} ogDescription={blog.description} altOGImage={blog.imageURL} ogUrl={`${RECIPES_ROUTE}${blog.path}/`} addLayoutProps>
      <BlogContent 
        marketOpen={marketOpen} 
        additionalItems={additionalItems}
        blog={blog}
        featuredProducts={featuredProducts}
      />
    </Layout>
  )
}

export const query = graphql`
  query($id: String!, $featuredProductIDs: [String]) {
    blog(id: {eq: $id}) {
      id
      title
      description
      directions
      content
      path
      blogHeaderDate
      imageURL
      ingredients
      prepTime
      cookTime
      yieldValue
      blogImage {
        childImageSharp {
            fluid(maxWidth: 750) {
            ...GatsbyImageSharpFluid
            }
        }
      }
    }
    allBlogAdditionalItem(filter: {blogID: {eq: $id}}) {
      edges {
        node {
          type
          value
          additionalImage {
            childImageSharp {
              fluid(maxWidth: 750) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allProduct(filter: {id: {in: $featuredProductIDs}}) {
      edges {
        node {
          id
          about
          name
          path
          price
          displayPrice
          priceOption
          status
          qtyAvailable
          priceOptionItems {
              description
              name
              price
              qtyAvailable
          }
          farm {
              path
              name
          }
          productImage {
              childImageSharp {
                  fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                  }
              }
          }
        }
      }
    }
    marketStatus {
      marketOpen
    }
  }
`